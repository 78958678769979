var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputUUIDProvider", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var uuid = ref.uuid
            return [
              _c(
                "div",
                { class: _vm.classes },
                [
                  _c("input", {
                    attrs: {
                      id: uuid,
                      disabled: _vm.disabled,
                      type: "checkbox"
                    },
                    domProps: { value: _vm.value, checked: _vm.value },
                    on: { input: _vm.onValueChange }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: uuid } }, [
                    _c("div", { staticClass: "toggler__state-background" }, [
                      _c("div", { staticClass: "toggler__state" })
                    ]),
                    _vm._v(" "),
                    _vm.label
                      ? _c("span", {
                          staticClass: "toggler__label",
                          domProps: { textContent: _vm._s(_vm.label) }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._t("append")
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }