var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tool-bar-breadcrumb" },
    [
      _vm.breadcrumb.icon
        ? _c(_vm.breadcrumbIcon, {
            tag: "Component",
            attrs: { "fill-color": _vm.breadcrumbIconFillColor }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("TranslatableTitleProvider", {
        attrs: { title: _vm.breadcrumb.title },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var translatableTitle = ref.translatableTitle
              return [
                _vm.breadcrumb.id
                  ? _c("NuxtLink", {
                      staticClass: "tool-bar-breadcrumb__link",
                      attrs: {
                        "aria-label": "Breadcrumb link",
                        to: {
                          name: _vm.breadcrumb.routeName,
                          params: { id: _vm.$route.params[_vm.breadcrumb.id] }
                        }
                      },
                      domProps: { textContent: _vm._s(_vm.breadcrumb.title) }
                    })
                  : _vm.breadcrumb.routeName
                  ? _c("NuxtLink", {
                      staticClass: "tool-bar-breadcrumb__link",
                      attrs: {
                        "aria-label": "Breadcrumb link",
                        to: { name: _vm.breadcrumb.routeName }
                      },
                      domProps: { textContent: _vm._s(translatableTitle) }
                    })
                  : _c("span", {
                      staticClass: "tool-bar-breadcrumb__title",
                      domProps: { textContent: _vm._s(translatableTitle) }
                    })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("span", { staticClass: "tool-bar-breadcrumb__divider" }, [_vm._v("/")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }