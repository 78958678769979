var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ToolBarButton",
    {
      ref: "toolBarButton",
      attrs: { selected: _vm.isFocused },
      nativeOn: {
        click: function($event) {
          return _vm.onClick.apply(null, arguments)
        }
      }
    },
    [
      _vm._t("input"),
      _vm._v(" "),
      _vm.isReadyToRender
        ? _c(
            "Dropdown",
            {
              attrs: {
                "parent-reference": _vm.$refs.toolBarButton,
                visible: _vm.isFocused
              },
              on: { "click-outside": _vm.onClickOutside }
            },
            [_vm._t("dropdown", null, { visible: _vm.isFocused })],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }