var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tool-bar" }, [
    _c(
      "section",
      { staticClass: "tool-bar__breadcrumbs" },
      [_vm._t("breadcrumbs")],
      2
    ),
    _vm._v(" "),
    _c("section", { staticClass: "tool-bar__actions" }, [_vm._t("actions")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }